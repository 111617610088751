import { render, staticRenderFns } from "./PieData.vue?vue&type=template&id=32095ae1&scoped=true&"
import script from "./PieData.vue?vue&type=script&lang=js&"
export * from "./PieData.vue?vue&type=script&lang=js&"
import style0 from "./PieData.vue?vue&type=style&index=0&id=32095ae1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32095ae1",
  null
  
)

export default component.exports